import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { Checkbox } from '@paljs/ui/Checkbox';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, createContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import '../../styles/common.css';
import ReactPaginate from 'react-paginate';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  dateFormat,
  checkNumber,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateGreaterOrEqual,
  resetJson,
  randomKey,
  buildQuery,
  ADMIN,
  getPageAccessFromState,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import toast, { Toaster } from 'react-hot-toast';
import { Page, Text, View, Document, StyleSheet, pdf } from '@react-pdf/renderer';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  margin-bottom: 10px;
  padding: 4px;
`;
const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const PdfComponent = ({
  pocNo,
  pocDate,
  customer,
  warp,
  pick,
  blend1,
  sort,
  weft,
  greyWidth,
  blend2,
  process,
  reed,
  greyLmtrKg,
  blend3,
  processRequired,
  finishRequired,
  greyGSM,
  washFastness,
  lightFastness,
  finishGSM,
  rubbingFastnessDry,
  rubbingFastnessWet,
  finishWidth,
  shrinkageWarp,
  shrinkageWeft,
  packingRequirement,
  pilling,
  whiteIndex,
  expDate,
  pieceLength,
  pocType,
  stamping,
  remarks,
  weave,
  design,
  quantity,
  shade,
  sampleReceived,
  receipts,
}: {
  pocNo: any;
  pocDate: any;
  customer: any;
  warp: any;
  pick: any;
  blend1: any;
  sort: any;
  weft: any;
  greyWidth: any;
  blend2: any;
  process: any;
  reed: any;
  greyLmtrKg: any;
  blend3: any;
  processRequired: any;
  finishRequired: any;
  greyGSM: any;
  washFastness: any;
  pilling: any;
  whiteIndex: any;
  lightFastness: any;
  finishGSM: any;
  rubbingFastnessDry: any;
  rubbingFastnessWet: any;
  finishWidth: any;
  shrinkageWarp: any;
  shrinkageWeft: any;
  packingRequirement: any;
  expDate: any;
  pieceLength: any;
  stamping: any;
  remarks: any;
  pocType: any;
  weave: any;
  design: any;
  quantity: any;
  shade: any;
  sampleReceived: any;
  receipts: any;
}) => {
  return (
    <Document>
      <Page
        style={{
          fontSize: '14px',
          fontFamily: 'Courier-Bold',
          padding: '12px',
        }}
      >
        <View
          style={{
            height: '100%',
            width: '100%',
            border: '0.75px solid black',
          }}
        >
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              flexDirection: 'row',
              borderBottom: '0.75px solid black',
              padding: '2px',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text>United Bleachers (P) LTD.,</Text>
              <Text>METTUPALAYAM - 641 301.</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text>POC NO.: {pocNo}</Text>
              <Text>POC DATE: {pocDate}</Text>
            </View>
          </View>
          <Text
            style={{
              textAlign: 'center',
              borderBottom: '0.75px solid black',
              fontSize: '19px',
            }}
          >
            PROCESS ORDER CONFIRMATION
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <View
              style={{
                borderRight: '0.75px solid black',
                width: '35%',
                height: '100%',
              }}
            >
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Text>Customer:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '17px',
                    textAlign: 'center',
                  }}
                >
                  {customer}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Warp:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {warp}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Reed:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {reed}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Grey Width cms:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {greyWidth}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Grey GSM:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {greyGSM}
                </Text>
              </View>

              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Blend</Text>
                {blend1 !== '-' && (
                  <Text
                    style={{
                      alignSelf: 'center',
                      fontSize: '17px',
                      textAlign: 'center',
                    }}
                  >
                    {blend1 === '-' ? '' : `${blend1}%`}
                  </Text>
                )}
                {blend2 !== '-' && (
                  <Text
                    style={{
                      alignSelf: 'center',
                      fontSize: '17px',
                      textAlign: 'center',
                    }}
                  >
                    {blend2 === '-' ? '' : `${blend2}%`}
                  </Text>
                )}
                {blend3 !== '-' && (
                  <Text
                    style={{
                      alignSelf: 'center',
                      fontSize: '17px',
                      textAlign: 'center',
                    }}
                  >
                    {blend3 === '-' ? '' : `${blend3}%`}
                  </Text>
                )}
              </View>
            </View>
            <View
              style={{
                borderRight: '0.75px solid black',
                width: '35%',
                height: '100%',
              }}
            >
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                  overflow: 'hidden',
                }}
              >
                <Text>Sort:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    textAlign: 'center',
                  }}
                >
                  {sort}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Weft:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {weft}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Pick:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {pick}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Finish Width cms:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {finishWidth}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Finish GSM:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {finishGSM}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Gry LMTR/KG / GLM:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {greyLmtrKg} / {(1000 / +greyLmtrKg).toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={{ width: '30%', height: '100%' }}>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Process:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {pocType}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Quantity:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {quantity ? `${quantity} Mts` : '-'}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Weave:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {weave}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Design:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {design}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Shade:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {shade}
                </Text>
              </View>
              <View
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '80px',
                }}
              >
                <Text>Sample Received:</Text>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  {sampleReceived}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <View
              style={{
                borderRight: '0.75px solid black',
                width: '100%',
                height: '100%',
              }}
            >
              <Text
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                }}
              >
                Process: {processRequired}
              </Text>
            </View>
            <View
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <Text
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                }}
              >
                Finish: {finishRequired}
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <View
              style={{
                borderRight: '0.75px solid black',
                width: '100%',
                height: '100%',
              }}
            >
              <Text
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                }}
              >
                Wash Fast: {washFastness}
              </Text>
              <Text
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                }}
              >
                Light Fast: {lightFastness}
              </Text>
            </View>
            <View
              style={{
                borderRight: '0.75px solid black',
                width: '100%',
                height: '100%',
              }}
            >
              <Text
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                }}
              >
                Rub Fast Dry: {rubbingFastnessDry}
              </Text>
              <Text
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                }}
              >
                Rub Fast Wet: {rubbingFastnessWet}
              </Text>
            </View>
            <View
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <Text
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                }}
              >
                Shrink Warp: {shrinkageWarp}
              </Text>
              <Text
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                }}
              >
                Shrink Weft: {shrinkageWeft}
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderBottom: '1px solid black',
            }}
          >
            <Text
              style={{
                width: '100%',
                borderRight: '1px solid black',
                padding: '2px',
              }}
            >
              Pilling: {pilling}
            </Text>
            <Text
              style={{
                width: '100%',
                padding: '2px',
                borderRight: '1px solid black',
              }}
            >
              White Index: {whiteIndex}
            </Text>
            <Text
              style={{
                width: '100%',
                padding: '2px',
              }}
            >
              Exp. Date: {expDate}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <View
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <Text
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                }}
              >
                Packing Requirement: {packingRequirement}
              </Text>
              <Text
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                }}
              >
                Piece Length: {pieceLength}
              </Text>
              <Text
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                }}
              >
                Stamping: {stamping}
              </Text>
              <Text
                style={{
                  borderBottom: '0.75px solid black',
                  padding: '2px',
                  flex: 'auto',
                }}
              >
                Receipts:{receipts}
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              padding: '2px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Text>{remarks}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const POPage = () => {
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const renderCount = useRef(0);
  const [orderQty, setOrderQty] = useState('');
  const [finishedQty, setFinishedQty] = useState('');
  const [pilling, setPilling] = useState('');
  const [whiteIndex, setWhiteIndex] = useState('');
  const [shade, setShade] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState([]);
  const [pocNumber, setPocNumber] = useState('**');
  const [poDate, setPoDate] = useState(dateFormat(new Date()));
  const [graRecepitOty, setGraRecepitOty] = useState([]);
  const [defaultUser, setDefaultUser] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [defaultSort, setDefaultSort] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [customer, setCustomer] = useState('');
  const [customersSort, setCustomersSort] = useState('');

  const [washFastness, setWashFastness] = useState('');
  const [lightFastness, setLightFastness] = useState('');
  const [rubbingFastnessDry, setRubbingFastnessDry] = useState('');
  const [rubbingFastnessWeft, setRubbingFastnessWeft] = useState('');
  const [shrinkageWarp, setShrinkageWarp] = useState('');
  const [shrinkageWeft, setShrinkageWeft] = useState('');
  const [greyGSM, setGreyGSM] = useState(0);
  const [finishGSM, setFinishGSM] = useState(0);
  const [finishWidth, setFinishWidth] = useState(0);
  const [pieceLength, setPieceLength] = useState('');
  const [deliveryDate, setDeliveryDate] = useState(dateFormat(new Date()));
  const [stamping, setStamping] = useState('');
  const [restrictedEditUsers, setRestrictedUsers] = useState([]);
  const [currMaxBalance, setCurrMaxBalance] = useState([]);
  const [currTotMeters, setcurrTotMeters] = useState([]);
  const [prevQty, setPrevQty] = useState([]);
  const [remarks, setRemarks] = useState([
    {
      name: 'Remarks',
      id: 1,
      value: `Colour Matching Source:
Finished Width Range in Cms: 
Coating Density: 
Others: `,
      length: 0,
    },
  ]);

  const [process, setProcess] = useState([
    {
      name: 'Bleaching',
      id: 1,
      checked: false,
    },
    {
      name: 'Dyeing',
      id: 2,
      checked: false,
    },
    {
      name: 'Coating',
      id: 3,
      checked: false,
    },
    {
      name: 'Others',
      id: 4,
      checked: false,
      text: '',
    },
  ]);

  const [processRequired, setProcessRequired] = useState([
    {
      name: 'Singeing',
      id: 1,
      checked: false,
    },
    {
      name: 'Desizing',
      id: 2,
      checked: false,
    },
    {
      name: 'Scouring',
      id: 11,
      checked: false,
    },
    {
      name: 'Mercerising',
      id: 3,
      checked: false,
    },
    {
      name: 'Heat Set',
      id: 4,
      checked: false,
    },
    {
      name: 'RFD',
      id: 5,
      checked: false,
    },
    {
      name: 'Full white',
      id: 6,
      checked: false,
    },
    {
      name: 'Dyeing',
      id: 7,
      checked: false,
    },
    {
      name: 'Finishing',
      id: 8,
      checked: false,
    },
    {
      name: 'Zero Zero',
      id: 9,
      checked: false,
    },
    {
      name: 'Calendering',
      id: 10,
      checked: false,
    },
  ]);

  const [finishRequired, setFinishRequired] = useState([
    {
      name: 'Soft',
      id: 1,
      checked: false,
    },
    {
      name: 'Stiff',
      id: 2,
      checked: false,
    },
    {
      name: 'FR',
      id: 3,
      checked: false,
    },
    {
      name: 'WR',
      id: 4,
      checked: false,
    },
    {
      name: 'SR',
      id: 5,
      checked: false,
    },
    {
      name: 'Resin/WF',
      id: 6,
      checked: false,
    },
    {
      name: 'Others',
      id: 7,
      checked: false,
    },
  ]);

  const [packing, setPacking] = useState([
    {
      name: 'Domestic',
      id: 1,
      checked: false,
    },
    {
      name: 'Export',
      id: 2,
      checked: false,
    },
    {
      name: 'Roll',
      id: 3,
      checked: false,
    },
    {
      name: 'Bale',
      id: 4,
      checked: false,
    },
    {
      name: 'Doublefold',
      id: 5,
      checked: false,
    },
    {
      name: 'Others',
      id: 6,
      checked: false,
      text: '',
    },
    {
      name: 'Bale Size/Qty',
      id: 7,
      checked: false,
      text: '',
    },
  ]);

  const [sampleReceived, setSampleReceived] = useState(false);
  const [defaultSampleReceived, setDefaultSampleReceived] = useState([{ label: 'No', value: false }]);
  const [pocType, setPocType] = useState();
  const [defaultPocType, setDefaultPocType] = useState();
  const sampleBoolean = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  const pocTypeOptions = [
    { label: 'Non-Optic', value: 'Non-Optic' },
    { label: 'Bleached', value: 'Bleached' },
    { label: 'Dyed', value: 'Dyed' },
    { label: 'Others', value: 'Others' },
  ];
  const [status, setStatus] = useState(true);
  const [defaultStatus, setDefaultStatus] = useState([{ label: 'Open', value: true }]);
  const statusOptions = [
    { label: 'Open', value: true },
    { label: 'Close', value: false },
  ];
  const [pickId, setPickId] = useState('');
  const authState = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(authState);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const [pocFinish, setPocFinish] = useState('');

  useEffect(() => {
    authCheck(authState.authData);
    getCustomers();
  }, []);

  useEffect(() => {
    setRestrictedUsers(['ublmtpadmin@gmail.com']);
  }, [authState]);

  useEffect(() => {
    getApi();
  }, [page]);

  useEffect(() => {
    if (pocType === 'Bleached') {
      setShade('white');
    } else if (pocType === 'Non-Optic') {
      setShade('Non-Optic');
    }
  }, [pocType]);

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'poDate':
        setPoDate(event.target.value);
        break;
      case 'washFastness':
        setWashFastness(event.target.value);
        break;
      case 'lightFastness':
        setLightFastness(event.target.value);
        break;
      case 'rubbingFastnessDry':
        setRubbingFastnessDry(event.target.value);
        break;
      case 'rubbingFastnessWeft':
        setRubbingFastnessWeft(event.target.value);
        break;
      case 'orderQty':
        setOrderQty(checkNumber(event.target, '8.2'));
        break;
      case 'finishedQty':
        setFinishedQty(checkNumber(event.target, '8.2'));
        break;
      case 'pilling':
        setPilling(checkNumber(event.target, '2.0'));
        break;
      case 'whiteIndex':
        setWhiteIndex(checkNumber(event.target, '3.0'));
        break;
      case 'shade':
        setShade(event.target.value);
        break;
      case 'shrinkageWarp':
        setShrinkageWarp(event.target.value);
        break;
      case 'shrinkageWeft':
        setShrinkageWeft(event.target.value);
        break;
      case 'greyGSM':
        setGreyGSM(checkNumber(event.target, '3.0'));
        break;
      case 'finishGSM':
        setFinishGSM(checkNumber(event.target, '3.0'));
        break;
      case 'finishWidth':
        setFinishWidth(checkNumber(event.target, '3.0'));
        break;
      case 'pieceLength':
        setPieceLength(event.target.value);
        break;
      case 'deliveryDate':
        setDeliveryDate(event.target.value);
        break;
      case 'stamping':
        setStamping(event.target.value);
        break;
      case 'sampleReceived':
        setSampleReceived(event.target.value);
        break;
      case 'pocFinish':
        setPocFinish(event.target.value);
        break;
    }
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setPocNumber('**');
    setPickId('');
    setPoDate(dateFormat(new Date()));
    setOrderQty('');
    setFinishedQty('');
    setWhiteIndex('');
    setShade('');
    setPilling('');
    setGraRecepitOty([]);
    setWashFastness('');
    setLightFastness('');
    setRubbingFastnessDry('');
    setRubbingFastnessWeft('');
    setShrinkageWarp('');
    setShrinkageWeft('');
    setGreyGSM(0);
    setFinishGSM(0);
    setFinishWidth(0);
    setPieceLength('');
    setDeliveryDate(dateFormat(new Date()));
    setStamping('');
    setCustomer('');
    setCustomersSort('');
    setSortOptions([]);
    setPocFinish('');
    setRemarks([
      {
        name: 'Remarks',
        id: 1,
        value: `Colour Matching Source:
Finished Width Range: 
Coating Density: 
Others: `,
        length: 0,
      },
    ]);
    setProcess([
      {
        name: 'Bleaching',
        id: 1,
        checked: false,
      },
      {
        name: 'Dyeing',
        id: 2,
        checked: false,
      },
      {
        name: 'Coating',
        id: 3,
        checked: false,
      },
      {
        name: 'Others',
        id: 4,
        checked: false,
        text: '',
      },
    ]);
    setProcessRequired([
      {
        name: 'Singeing',
        id: 1,
        checked: false,
      },
      {
        name: 'Desizing',
        id: 2,
        checked: false,
      },
      {
        name: 'Mercerising',
        id: 3,
        checked: false,
      },
      {
        name: 'Heat Set',
        id: 4,
        checked: false,
      },
      {
        name: 'RFD',
        id: 5,
        checked: false,
      },
      {
        name: 'Full white',
        id: 6,
        checked: false,
      },
      {
        name: 'Dyeing',
        id: 7,
        checked: false,
      },
      {
        name: 'Finishing',
        id: 8,
        checked: false,
      },
      {
        name: 'Zero Zero',
        id: 9,
        checked: false,
      },
      {
        name: 'Calendering',
        id: 10,
        checked: false,
      },
      {
        name: 'Scouring',
        id: 11,
        checked: false,
      },
    ]);
    setFinishRequired([
      {
        name: 'Soft',
        id: 1,
        checked: false,
      },
      {
        name: 'Stiff',
        id: 2,
        checked: false,
      },
      {
        name: 'FR',
        id: 3,
        checked: false,
      },
      {
        name: 'WR',
        id: 4,
        checked: false,
      },
      {
        name: 'SR',
        id: 5,
        checked: false,
      },
      {
        name: 'Resin/WF',
        id: 6,
        checked: false,
      },
      {
        name: 'Others',
        id: 7,
        checked: false,
      },
    ]);
    setPacking([
      {
        name: 'Domestic',
        id: 1,
        checked: false,
      },
      {
        name: 'Export',
        id: 2,
        checked: false,
      },
      {
        name: 'Roll',
        id: 3,
        checked: false,
      },
      {
        name: 'Bale',
        id: 4,
        checked: false,
      },
      {
        name: 'Doublecold',
        id: 5,
        checked: false,
      },
      {
        name: 'Others',
        id: 6,
        checked: false,
        text: '',
      },
      {
        name: 'Bale Size/Qty',
        id: 7,
        checked: false,
        text: '',
      },
    ]);

    setCustomersSort('');
    setCustomer('');
    setDefaultUser([]);
    setDefaultSort([]);
    setPickId('');
    setSampleReceived(false);
    setDefaultSampleReceived([{ label: 'No', value: false }]);
    setStatus(true);
    setPocType([]);
    setDefaultPocType([]);
    setDefaultStatus([{ label: 'Open', value: true }]);
    setcurrTotMeters([]);
    setCurrMaxBalance([]);
    setPrevQty([]);
    resetRequired();
  };

  const handleModalOpen = (id) => {
    document.getElementsByTagName('body')[0].style.position = 'relative';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    showProcess(id);
  };

  const handleModalClose = (event) => {
    setIsModalOpen(false);
    document.getElementsByTagName('body')[0].style.position = 'unset';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const modalWrapperClick = (e) => {
    if (e.target.classList.contains('popup-parent')) {
      handleModalClose();
    }
  };

  const popup = isModalOpen ? (
    <div className="popup-parent" onClick={(e) => modalWrapperClick(e)}>
      <div className="popup-wrapper">
        <div
          className="pop-up-close"
          onClick={() => {
            handleModalClose();
          }}
        >
          x
        </div>
        <div className="pop-content">
          <h5>Process Order Confirmation</h5>
          <table className="pop-up-table">
            <tr>
              <td>POC Number</td>
              <td>{showData.id}</td>
            </tr>
            <tr>
              <td>POC Date</td>
              <td>{dateFormat(showData.attributes.poDate)}</td>
            </tr>
            <tr>
              <td>Customer</td>
              <td>
                {showData.attributes.customer.data
                  ? showData.attributes.customer.data.attributes.userCode +
                    ' - ' +
                    showData.attributes.customer.data.attributes.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td>Customer Sort</td>
              <td>
                {showData.attributes.customersSort.data
                  ? showData.attributes.customersSort.data.attributes.sortName
                  : ''}
              </td>
            </tr>
            <tr>
              <td>GRA Receipt</td>
              <td>
                {showData.attributes.graRecepitOty &&
                  showData.attributes.graRecepitOty.map(
                    (rw, i) =>
                      rw.qty > 0 && (
                        <div key={i} className="grey-label">
                          {rw.name} Qty: {rw.qty}
                        </div>
                      ),
                  )}
              </td>
            </tr>
            <tr>
              <td>Process</td>
              <td>
                {showData.attributes.process.map((record) =>
                  record.checked ? <div className="grey-label">{record.name}</div> : null,
                )}
              </td>
            </tr>
            <tr>
              <td>Wash Fastness</td>
              <td>{showData.attributes.washFastness}</td>
            </tr>
            <tr>
              <td>Light Fastness</td>
              <td>{showData.attributes.lightFastness}</td>
            </tr>
            <tr>
              <td>Rubbing Fastness Dry</td>
              <td>{showData.attributes.rubbingFastnessDry}</td>
            </tr>
            <tr>
              <td>Rubbing Fastness Wet</td>
              <td>{showData.attributes.rubbingFastnessWeft}</td>
            </tr>
            <tr>
              <td>Shrinkage Warp</td>
              <td>{showData.attributes.shrinkageWarp}</td>
            </tr>
            <tr>
              <td>Shrinkage Weft</td>
              <td>{showData.attributes.shrinkageWeft}</td>
            </tr>
            <tr>
              <td>Grey GSM</td>
              <td>{showData.attributes.greyGSM}</td>
            </tr>
            <tr>
              <td>Finish GSM</td>
              <td>{showData.attributes.finishGSM}</td>
            </tr>
            <tr>
              <td>Finish Width</td>
              <td>{showData.attributes.finishWidth}</td>
            </tr>
            <tr>
              <td>Process required</td>
              <td>
                {showData.attributes.processRequired.map((record) =>
                  record.checked ? <div className="grey-label">{record.name}</div> : null,
                )}
              </td>
            </tr>
            <tr>
              <td>Finish required</td>
              <td>
                {showData.attributes.finishRequired.map((record) =>
                  record.checked ? <div className="grey-label">{record.name}</div> : null,
                )}
              </td>
            </tr>
            <tr>
              <td>Packing Requirement</td>
              <td>
                {showData.attributes.packing.map((record) =>
                  record.checked ? <div className="grey-label">{record.name}</div> : null,
                )}
              </td>
            </tr>
            <tr>
              <td>Piece Length</td>
              <td>{showData.attributes.pieceLength}</td>
            </tr>
            <tr>
              <td>Expected Delivery Date</td>
              <td>{dateFormat(showData.attributes.deliveryDate)}</td>
            </tr>
            <tr>
              <td>Sample Received</td>
              <td>{showData.attributes.sampleRecevied ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>Stamping</td>
              <td>{showData.attributes.stamping}</td>
            </tr>
            <tr>
              <td>Remarks</td>
              <td>
                {showData.attributes.remarks &&
                  showData.attributes.remarks.map((remark, i) => <div>{remark.value}</div>)}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  ) : null;

  const getCustomers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=4', {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setUserOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getSorts = (cId) => {
    setCustomersSort('');
    setDefaultSort([]);
    showLoader();
    axios
      .get(baseUri() + `user-sorts?populate[sort][fields][0]=greyWidth&filters[user][id][$eq]=${cId}`, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.data.forEach(function (item, index) {
          options.push({
            label: item.attributes.sortName,
            value: item.id,
            greyWidth: item?.attributes?.sort?.data?.attributes?.greyWidth,
          });
        });
        setSortOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getReceipts = (sortId, preData = false) => {
    axios
      .get(baseUri() + 'grey-receipt-advice/open-customer-gra?customersSort=' + sortId, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let preInfo = [];
        if (preData == false) {
          preInfo = [];
        } else {
          preInfo = preData;
        }
        let resFound = 0;
        response.data.data.graList.forEach(function (item, index) {
          if (preInfo.length > 0) {
            resFound = preInfo.findIndex((ele, ind) => ele.id === item.id);
          } else {
            resFound = -1;
          }
          if (resFound == -1) {
            preInfo.push({
              name: item.receipt_number,
              id: item.id,
              total_meters: item.total_meters,
              balance: item.balance,
              checked: false,
              qty: 0,
            });
          }
        });
        setGraRecepitOty(preInfo);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getApi = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          buildQuery(
            'process-orders?populate=*&sort[0]=id:desc&pagination[page]=' + page + '&pagination[pageSize]=20',
            tableFilter,
            filterColumns,
          ),
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        let len = response.data.data.length ? response.data.data[response.data.data.length - 1].id + 1 : 1;
        setPocNumber('**');
        setTableData(response.data.data);
        setTotalPage(response.data.meta.pagination.pageCount);
        hideLoader();
      })
      .catch((error) => {
        //return  error ;
      });
  };

  const userChange = (event) => {
    setCustomer(event.value);
    setDefaultUser({ label: event.label, value: event.value });
    pullDownReset('customer');
    setGraRecepitOty([]);
    getSorts(event.value);
  };

  const sortChange = (event) => {
    setCustomersSort(event.value);
    setDefaultSort({ label: event.label, value: event.value, greyWidth: event.greyWidth });
    pullDownReset('customersSort');
    setGraRecepitOty([]);
    getReceipts(event.value);
  };

  const onChangeProcessCheckbox = (value, index, name) => {
    resetJson(false, 'process');
    name == 'Others' ? (process[index]['text'] = value) : (process[index]['checked'] = value);
    setProcess([...process]);
  };

  const onChangeProcessRequiredCheckbox = (value: boolean, index: number) => {
    resetJson(false, 'processRequired');
    processRequired[index]['checked'] = value;
    setProcessRequired([...processRequired]);
  };

  const onChangeFinishRequiredCheckbox = (value: boolean, index: number) => {
    resetJson(false, 'finishRequired');
    finishRequired[index]['checked'] = value;
    setFinishRequired([...finishRequired]);
  };

  const onChangeGRACheckbox = (value: boolean, index: number, bal: number) => {
    resetJson(false, 'graRecepitOty');
    if (bal > 0) {
      checkNumber(event.target, '7.2');
      if (value > bal) {
        toast.dismiss();
        toast.error(`Available balance for the current receipt is ${bal}`);
        graRecepitOty[index]['qty'] = bal;
      } else {
        graRecepitOty[index]['qty'] = value;
      }
    } else {
      graRecepitOty[index]['checked'] = value;
      graRecepitOty[index]['qty'] = '';
      if (value) {
        axios
          .get(baseUri() + `process-order/qty?id=${graRecepitOty[index]['name']}`, {
            headers: {
              authorization: 'Bearer ' + authState.authData,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            response = response.data.data;
            let currMaxArray = [...currMaxBalance];
            let currTotArray = [...currTotMeters];
            currMaxArray[index] = +response?.totMeters - +response?.totQty;
            currTotArray[index] = +response.totMeters;
            setcurrTotMeters(currTotArray);
            setCurrMaxBalance(currMaxArray);
          });
      } else {
        let currMaxArray = [...currMaxBalance];
        let currTotArray = [...currTotMeters];
        currMaxArray[index] = undefined;
        currTotArray[index] = undefined;
        setcurrTotMeters(currTotArray);
        setCurrMaxBalance(currMaxArray);
      }
    }

    let tot = graRecepitOty.reduce(function (a, b) {
      return a + Number(b.qty);
    }, 0);

    setOrderQty(tot);
    setGraRecepitOty([...graRecepitOty]);
  };

  const onChangePackingCheckbox = (value, index, name) => {
    resetJson(false, 'packing');
    if (name == 'Bale Size/Qty') {
      checkNumber(event.target, '7.2');
      packing[index]['text'] = value;
    } else if (name == 'Others') {
      packing[index]['text'] = value;
    } else {
      packing[index]['checked'] = value;
    }

    setPacking([...packing]);
  };

  const onChangeRemarks = (e, index: number) => {
    let maxLength = remarks[index]['maxLength'];
    let value = e.target.value;
    let length = e.target.value.length;
    remarks[index]['value'] = length <= maxLength ? value : value.substring(0, maxLength);
    remarks[index]['length'] = length <= maxLength ? length : maxLength;
    setRemarks([...remarks]);
  };

  const errorMessageAppender = (input: Element, errorMessage: string) => {
    resetRequired(false, input);
    input.style.border = '1px solid red';
    let span = document.createElement('span');
    span.className = 'error-message';
    span.innerHTML = errorMessage;
    input.parentElement.appendChild(span);
  };

  const handleSampleChange = (event) => {
    setSampleReceived(event.value);
    setDefaultSampleReceived({ label: event.label, value: event.value });
    pullDownReset('sampleReceived');
  };

  const handlePocTypeChange = (event) => {
    setShade('');
    setPocType(event.value);
    setDefaultPocType({ label: event.label, value: event.value });
    pullDownReset('pocType');
  };

  const handleStatusChange = (event) => {
    setStatus(event.value);
    setDefaultStatus({ label: event.label, value: event.value });
    pullDownReset('status');
  };

  const postApi = () => {
    const postData = {
      data: {
        finishedQty,
        orderQty,
        whiteIndex,
        shade,
        pilling,
        poDate,
        graRecepitOty,
        customer,
        customersSort,
        washFastness,
        lightFastness,
        rubbingFastnessDry,
        rubbingFastnessWeft,
        shrinkageWarp,
        shrinkageWeft,
        greyGSM,
        finishGSM,
        finishWidth,
        pieceLength,
        deliveryDate,
        stamping,
        remarks,
        sampleReceived,
        pocType,
        processRequired,
        finishRequired,
        packing,
        status,
        pocFinish,
      },
    };
    let validate = requireCheck(postData);
    let deliveryDateValid = dateGreaterOrEqual(deliveryDate, poDate, 'deliveryDate', 'Invalid delivery date');
    if (+defaultSort.greyWidth < +finishWidth) {
      toast.dismiss();
      toast.error(`Finish width (${finishWidth}cm) must be less than Grey Width (${defaultSort.greyWidth}cm)`);
      return false;
    }
    if (!validate || !deliveryDateValid) {
      return false;
    }
    for (const key in postData['data']) {
      if (postData['data'][key] === '') {
        delete postData['data'][key];
      }
    }
    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'process-orders' + '/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    } else {
      axios
        .post(baseUri() + 'process-orders', postData, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const showProcess = (id) => {
    showLoader();
    axios
      .get(baseUri() + `process-orders/${id}?populate=*`, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setShowData(response.data.data);
        setIsModalOpen(true);
        hideLoader();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((error) => {
        //return  error;
      });
  };

  const editProcessOrder = (id, copy = false) => {
    showLoader();
    clearFormData();
    axios
      .get(
        baseUri() +
          `process-orders/${id}?populate[customersSort][populate][sort][fields][0]=greyWidth&populate[customer]=*`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        response = response.data.data;
        // Check whether Copy or Edit
        if (!copy) {
          setSave(pageAccess.edit === 1);
          setPickId(response.id);
          setPocNumber(response.id);
        } else {
          setSave(pageAccess.write === 1);
          setPocNumber('**');
        }
        if (response.attributes.finishRequired.length === 6) {
          response.attributes.finishRequired.push({
            name: 'Others',
            id: 7,
            checked: false,
          });
        }
        const initialGraOty = [];
        response.attributes.graRecepitOty.forEach((t, i) => {
          initialGraOty.push(+t.qty);
        });
        setPrevQty(initialGraOty);
        // Set Strings
        setPoDate(dateFormat(copy ? new Date().toISOString() : response.attributes.poDate));
        setOrderQty(response.attributes.orderQty);
        setFinishedQty(response.attributes.finishedQty);
        setWhiteIndex(response.attributes?.whiteIndex);
        setShade(response.attributes?.shade);
        setPilling(response.attributes?.pilling);
        setProcess(response.attributes.process);
        setWashFastness(response.attributes.washFastness);
        setLightFastness(response.attributes.lightFastness);
        setRubbingFastnessDry(response.attributes.rubbingFastnessDry);
        setRubbingFastnessWeft(response.attributes.rubbingFastnessWeft);
        setShrinkageWarp(response.attributes.shrinkageWarp);
        setShrinkageWeft(response.attributes.shrinkageWeft);
        setGreyGSM(response.attributes.greyGSM);
        setFinishGSM(response.attributes.finishGSM);
        setFinishWidth(response.attributes.finishWidth);
        setProcessRequired(response.attributes.processRequired);
        setFinishRequired(response.attributes.finishRequired);
        setPacking(response.attributes.packing);
        setPieceLength(response.attributes.pieceLength);
        setDeliveryDate(dateFormat(copy ? new Date().toISOString() : response.attributes.deliveryDate));
        setStamping(response.attributes.stamping);
        setRemarks(response.attributes.remarks);
        setPocFinish(response.attributes.pocFinish);
        // Set pull down data
        setSampleReceived(response.attributes.sampleReceived);
        setDefaultSampleReceived({
          label: response.attributes.sampleReceived ? 'Yes' : 'No',
          value: response.attributes.sampleReceived ? true : false,
        });

        setStatus(response.attributes.status);
        setDefaultStatus({
          label: response.attributes.status ? 'Open' : 'Close',
          value: response.attributes.status ? true : false,
        });

        setPocType(response.attributes?.pocType);
        if (response.attributes?.pocType) {
          setDefaultPocType({
            label: response.attributes.pocType,
            value: response.attributes?.pocType,
          });
        } else {
          setDefaultPocType([]);
        }

        // Customer
        setDefaultUser({
          label:
            response.attributes.customer.data.attributes.userCode +
            ' - ' +
            response.attributes.customer.data.attributes.name,
          value: response.attributes.customer.data.id,
        });
        setCustomer(response.attributes.customer.data.id);

        // customer Sorts
        getSorts(response.attributes.customer.data.id);
        setDefaultSort({
          label: response.attributes.customersSort.data.attributes.sortName,
          value: response.attributes.customersSort.data.id,
          greyWidth: response.attributes.customersSort.data.attributes.sort.data.attributes.greyWidth,
        });
        setCustomersSort(response.attributes.customersSort.data.id);

        // Gra Receipts
        getReceipts(response.attributes.customersSort.data.id, response.attributes.graRecepitOty);

        // let selected = [];
        // let opts = [];
        // response.attributes.graIds.data.forEach(function (item, index) {
        //   selected.push(item.id);
        //   opts.push({ label: item.attributes.receiptNumber, value: item.id, selected: true });
        // });
        //setGraRecepitOty(response.attributes.graRecepitOty);

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        hideLoader();
      })
      .catch((error) => {
        console.log(error);
        //return  error;
      });
  };

  const deleteProcessOrder = (id) => {
    const removeGroup = window.confirm('Would you like to delete this POC?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'process-orders/' + id, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const handlePrint = (id) => {
    axios
      .get(
        baseUri() +
          `process-orders/${id}?populate[0]=customer&populate[1]=customersSort&populate[2]=graIds&populate[3]=customersSort.sort`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(async (res) => {
        // setTableData(res.data.data);
        res = await res.data.data;
        let attributes = await res.attributes;
        if (attributes.idPrcType) {
          filteredType = idprcoptions.filter((data) => {
            return data.value == attributes.idPrcType;
          });
          // setDefaultIdPrcTypeLabel(filteredType[0].label);
        }
        let totQuantity = 0;
        let checkedGreyReceipts: any = [];
        let processRequiredArray: any = [];
        let finishRequiredArray: any = [];
        let packingArray: any = [];
        attributes?.graRecepitOty.forEach((t) => {
          totQuantity += +t.qty;
          if (t.checked) {
            checkedGreyReceipts.push({
              name: t.name,
              qty: t.qty,
            });
          }
        });
        attributes?.processRequired?.forEach((t) => {
          if (t.checked) {
            processRequiredArray.push(t.name);
          }
        });
        attributes?.finishRequired?.forEach((t) => {
          if (t.checked) {
            if (t.name !== 'Others') {
              finishRequiredArray.push(t.name);
            } else {
              finishRequiredArray.push(attributes?.pocFinish);
            }
          }
        });
        attributes?.packing?.forEach((t) => {
          if (t.checked) {
            packingArray.push(t.name);
          }
        });
        let pdfData = {
          pocNo: res?.id,
          pocDate: new Date(attributes.poDate).toLocaleDateString('en-GB'),
          customer: attributes?.customer?.data?.attributes?.name,
          warp: attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.warp || '-',
          pick: attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.pick || '-',
          blend1: `${attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend1 || ''}-${
            attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent1 || ''
          }`,
          sort: attributes?.customersSort?.data?.attributes?.sortName,
          weft: attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.weft || '-',
          greyWidth: attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.greyWidth,
          blend2: `${attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend2 || ''}-${
            attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent2 || ''
          }`,
          process: attributes?.process?.map((t) => t.name).join(', '),
          reed: attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.reed,
          greyLmtrKg: attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.linearMeter,
          blend3: `${attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blend3 || ''}-${
            attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.blendPercent3 || ''
          }`,
          processRequired: processRequiredArray.join(', '),
          finishRequired: finishRequiredArray.join(', '),
          greyGSM: +attributes?.greyGSM || '-',
          washFastness: attributes?.washFastness || '-',
          lightFastness: attributes?.lightFastness || '-',
          finishGSM: +attributes?.finishGSM || '-',
          rubbingFastnessDry: attributes?.rubbingFastnessDry || '-',
          rubbingFastnessWet: attributes?.rubbingFastnessWeft || '-',
          finishWidth: +attributes?.finishWidth || '-',
          shrinkageWarp: attributes?.shrinkageWarp || '-',
          shrinkageWeft: attributes?.shrinkageWeft || '-',
          packingRequirement: packingArray.join(', '),
          expDate: new Date(attributes?.deliveryDate).toLocaleDateString('en-GB'),
          pieceLength: attributes?.pieceLength || '-',
          stamping: attributes?.stamping || '-',
          remarks: attributes?.remarks?.map((t) => t.value).join(' \n'),
          pocType: attributes?.pocType || '-',
          weave: attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.weave || '-',
          design: attributes?.customersSort?.data?.attributes?.sort?.data?.attributes?.design || '-',
          quantity: totQuantity,
          shade: attributes?.shade || '-',
          sampleReceived: attributes?.sampleReceived ? 'Yes' : 'No',
          pilling: attributes?.pilling || '-',
          whiteIndex: attributes?.whiteIndex || '-',
          receipts: checkedGreyReceipts.map((t) => ` ${t.name} (${+t.qty} Mts)`).join(','),
        };
        let blobData = await pdf(<PdfComponent {...pdfData} />).toBlob();
        let objUrl = await URL.createObjectURL(blobData);
        const aEle = document.createElement('a');
        aEle.target = '_blank';
        aEle.href = objUrl;
        aEle.click();
        URL.revokeObjectURL(objUrl);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      name: 'Action',
      width: '20%',
      cell: (row) => (
        <div>
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            onClick={() => {
              editProcessOrder(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          <ActionBtn
            data-tag="allowRowEvents"
            className="btn-rad ml-10"
            onClick={() => {
              handleModalOpen(row.id);
            }}
          >
            {' '}
            <EvaIcon name="eye" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteProcessOrder(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtnprint-color"
            onClick={() => {
              handlePrint(row.id);
            }}
          >
            {' '}
            <EvaIcon name="printer-outline" />{' '}
          </ActionBtn>
        </div>
      ),
    },
    {
      name: 'POC No.',
      selector: (row) => `${row.id}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'POC Date',
      selector: (row) => `${dateFormat(row.attributes.poDate)}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Order Qty',
      selector: (row) => `${row.attributes.orderQty}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'POC Type',
      selector: (row) => `${row.attributes.pocType}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Shade',
      selector: (row) => `${row.attributes.shade}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Customer',
      selector: (row) =>
        `${
          row.attributes.customer.data
            ? row.attributes.customer.data.attributes.userCode + ' - ' + row.attributes.customer.data.attributes.name
            : ''
        }`,
      sortable: true,
      maxWidth: '25%',
    },
    {
      name: 'Sort',
      selector: (row) =>
        `${row.attributes.customersSort.data ? row.attributes.customersSort.data.attributes.sortName : ''}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Status',
      selector: (row) => `${row.attributes.status ? 'Open' : 'Close'}`,
      sortable: true,
      maxWidth: '5%',
    },
  ];

  const filterColumns = [
    'id',
    'poDate',
    'customer.userCode',
    'customer.name',
    'customersSort.sortName',
    'sampleReceived',
    'pocType',
    'shade',
    'orderQty',
  ];

  return (
    <>
      {popup}
      <SEO title="Process Order Confirmation" />
      <Row>
        <Toaster />
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Process Order Confirmation</header>
            <CardBody>
              <Row>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>POC Number</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="pocNumber"
                      placeholder="POC Number"
                      value={pocNumber}
                      readOnly
                      className="pocNumber"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>
                    POC Date <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="poDate"
                      placeholder="PO Date *"
                      onChange={(e) => handleChange(e)}
                      value={poDate}
                      readOnly={restrictedEditUsers.includes(authState.authEmail)}
                      className="poDate require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>
                    Select Customer <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => userChange(e)}
                    options={userOptions}
                    id="customer"
                    name="customer"
                    placeholder="Select Customer *"
                    value={defaultUser}
                    className="pull-down require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>
                    Select Sort <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => sortChange(e)}
                    options={sortOptions}
                    id="customersSort"
                    name="customersSort"
                    placeholder="Select Sort *"
                    value={defaultSort}
                    className="pull-down require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <div className="json-container json-require" id="graRecepitOty">
                    <label>Grey Receipt</label>
                    <Row>
                      {graRecepitOty &&
                        graRecepitOty.map((data, key) => {
                          return (
                            <>
                              <Col key={key} breakPoint={{ xs: 12, md: 2 }}>
                                <Checkbox
                                  checked={data.checked}
                                  status="Success"
                                  onChange={(value) => onChangeGRACheckbox(value, key, -1)}
                                >
                                  {data.name}
                                  <br />
                                  Total Mtr: {data.total_meters}
                                  <br />
                                  Balance Mtr:{' '}
                                  {currMaxBalance[key] === undefined
                                    ? 'Click'
                                    : currTotMeters[key] === 0
                                    ? data.total_meters
                                    : currMaxBalance[key] + (prevQty[key] ? prevQty[key] : 0)}
                                </Checkbox>
                              </Col>
                              {data.checked && (
                                <Input className="othersParent" fullWidth size="Small">
                                  <input
                                    type="text"
                                    placeholder="Qty"
                                    readOnly={currTotMeters[key] !== 0 && currMaxBalance[key] + prevQty[key] <= 0}
                                    onChange={(e) =>
                                      onChangeGRACheckbox(
                                        e.target.value,
                                        key,
                                        currTotMeters[key] === 0
                                          ? data.total_meters
                                          : +currMaxBalance[key] + (prevQty[key] ? prevQty[key] : 0),
                                      )
                                    }
                                    className="othersInput"
                                    value={data.qty}
                                  />
                                </Input>
                              )}
                            </>
                          );
                        })}
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <Col className="inline-block">
                    <label>
                      Total Order Qty (Meters/Kg) <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="number"
                        name="orderQty"
                        placeholder="Total Order Qty"
                        onChange={(e) => handleChange(e)}
                        value={orderQty}
                        readOnly
                        className="orderQty require"
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Wash Fastness <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        name="washFastness"
                        placeholder="Wash Fastness"
                        onChange={(e) => handleChange(e)}
                        value={washFastness}
                        className="washFastness require"
                        maxLength="20"
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Light Fastness <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        name="lightFastness"
                        placeholder="Light Fastness"
                        onChange={(e) => handleChange(e)}
                        value={lightFastness}
                        className="lightFastness require"
                        maxLength="20"
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Rubbing Fastness Dry <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        name="rubbingFastnessDry"
                        placeholder="Rubbing Fastness Dry"
                        onChange={(e) => handleChange(e)}
                        value={rubbingFastnessDry}
                        className="rubbingFastnessDry require"
                        maxLength="20"
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Sample Received <span className="red">*</span>
                    </label>
                    <SelectField
                      fullWidth
                      onChange={(e) => handleSampleChange(e)}
                      options={sampleBoolean}
                      id="sampleReceived"
                      name="sampleReceived"
                      placeholder="Sample Received?"
                      value={defaultSampleReceived}
                      className="pull-down"
                    />
                  </Col>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <Col className="inline-block">
                    <label>
                      Total Finished Qty (Meters/Kg) <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="number"
                        name="finishedQty"
                        placeholder="Total Finished Qty"
                        onChange={(e) => handleChange(e)}
                        value={finishedQty}
                        className="finishedQty require"
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Rubbing Fastness Wet <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        name="rubbingFastnessWeft"
                        placeholder="Rubbing Fastness Wet"
                        onChange={(e) => handleChange(e)}
                        value={rubbingFastnessWeft}
                        className="rubbingFastnessWeft require"
                        maxLength="20"
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Shrinkage Warp <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        name="shrinkageWarp"
                        placeholder="Shrinkage Warp"
                        onChange={(e) => handleChange(e)}
                        value={shrinkageWarp}
                        className="shrinkageWarp require"
                        maxLength="20"
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Shrinkage Weft <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        name="shrinkageWeft"
                        placeholder="Shrinkage Weft"
                        onChange={(e) => handleChange(e)}
                        value={shrinkageWeft}
                        className="shrinkageWeft require"
                        maxLength="20"
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Stamping <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <textarea
                        rows={1}
                        onChange={(e) => handleChange(e)}
                        placeholder="Stamping"
                        name="stamping"
                        value={stamping}
                        className="stamping require"
                      />
                    </Input>
                  </Col>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <Col className="inline-block">
                    <label>
                      POC Type <span className="red">*</span>
                    </label>
                    <SelectField
                      fullWidth
                      onChange={(e) => handlePocTypeChange(e)}
                      options={pocTypeOptions}
                      id="pocType"
                      name="pocType"
                      placeholder="POC Type"
                      value={defaultPocType}
                      className="pull-down require"
                    />
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Pilling <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        maxLength={2}
                        name="pilling"
                        placeholder="Pilling"
                        value={pilling}
                        onChange={(e) => handleChange(e)}
                        className="pilling require"
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Grey GSM <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        name="greyGSM"
                        placeholder="Grey GSM"
                        onChange={(e) => handleChange(e)}
                        value={greyGSM}
                        className="greyGSM require"
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Finish GSM <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        name="finishGSM"
                        placeholder="Finish GSM"
                        onChange={(e) => handleChange(e)}
                        value={finishGSM}
                        className="finishGSM require"
                      />
                    </Input>
                  </Col>

                  <Col className="inline-block">
                    <label>Expected Delivery Date</label>
                    <Input fullWidth size="Small">
                      <input
                        type="date"
                        name="deliveryDate"
                        placeholder="Expected Delivery Date "
                        onChange={(e) => handleChange(e)}
                        value={deliveryDate}
                        className="deliveryDate dateValidation"
                      />
                    </Input>
                  </Col>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <Col className="inline-block">
                    <label>
                      Shade {pocType === 'Dyed' || pocType === 'Others' ? <span className="red">*</span> : ''}
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        name="shade"
                        placeholder="Shade"
                        onChange={(e) => handleChange(e)}
                        value={shade}
                        maxLength={15}
                        readOnly={pocType !== 'Dyed' && pocType !== 'Others'}
                        className={`${pocType === 'Dyed' || pocType === 'Others' ? 'require' : ''} shade`}
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Whiteness Index <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        maxLength={3}
                        name="whiteIndex"
                        placeholder="Whiteness Index"
                        onChange={(e) => handleChange(e)}
                        value={whiteIndex}
                        className="whiteIndex require"
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Finish Width Cms <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        name="finishWidth"
                        placeholder="Finish Width"
                        onChange={(e) => handleChange(e)}
                        value={finishWidth}
                        className="finishWidth require"
                      />
                    </Input>
                  </Col>
                  <Col className="inline-block">
                    <label>
                      Piece Length <span className="red">*</span>
                    </label>
                    <Input fullWidth size="Small">
                      <input
                        type="text"
                        name="pieceLength"
                        placeholder="Piece Length"
                        onChange={(e) => handleChange(e)}
                        value={pieceLength}
                        maxlength="30"
                        className="pieceLength require"
                      />
                    </Input>
                  </Col>
                </Col>
              </Row>
              {remarks &&
                remarks.map((data, key) => {
                  return (
                    <Col key={key} breakPoint={{ xs: 12, md: 12 }}>
                      <label>{data.name}</label>
                      <Input fullWidth size="Small">
                        <textarea
                          rows={4}
                          onChange={(e) => onChangeRemarks(e, key)}
                          placeholder={data.name}
                          value={data.value}
                        />
                      </Input>
                    </Col>
                  );
                })}
              <Row>
                {/* <Col breakPoint={{ xs: 12, md: 12 }}>
                  <div className="json-container json-require" id="process">
                    <label>
                      Process <span className="red">*</span>
                    </label>
                    <Row className="mh42">
                      {process &&
                        process.map((data, key) => {
                          return (
                            <Col key={key} breakPoint={{ xs: 12, md: 3 }}>
                              {data.name == 'Others' ? (
                                <>
                                  <label className="othersLable">Others</label>
                                  <Input className="othersParent" fullWidth size="Small">
                                    <input
                                      type="text"
                                      placeholder="Others"
                                      onChange={(e) => onChangeProcessCheckbox(e.target.value, key, data.name)}
                                      className="othersInput"
                                      value={data.text}
                                    />
                                  </Input>
                                </>
                              ) : (
                                <Checkbox
                                  checked={data.checked}
                                  status="Success"
                                  onChange={(value) => onChangeProcessCheckbox(value, key)}
                                >
                                  {data.name}
                                </Checkbox>
                              )}
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                </Col> */}
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <div className="json-container json-require" id="processRequired">
                    <label>
                      Process required <span className="red">*</span>
                    </label>
                    <Row>
                      {processRequired &&
                        processRequired.map((data, key) => {
                          return (
                            <Col key={key} breakPoint={{ xs: 12, md: 2 }}>
                              <Checkbox
                                checked={data.checked}
                                status="Success"
                                onChange={(value) => onChangeProcessRequiredCheckbox(value, key)}
                              >
                                {data.name}
                              </Checkbox>
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <div className="json-container json-require" id="finishRequired">
                    <label>
                      Finish required <span className="red">*</span>
                    </label>
                    <Row>
                      {finishRequired &&
                        finishRequired.map((data, key) => {
                          return (
                            <Col key={key} breakPoint={{ xs: 12, md: 2 }}>
                              <Checkbox
                                checked={data.checked}
                                status="Success"
                                onChange={(value) => onChangeFinishRequiredCheckbox(value, key)}
                              >
                                {data.name}
                              </Checkbox>
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <label>
                    POC Finish&nbsp;
                    {finishRequired.filter((t) => t.name === 'Others' && t.checked).length === 1 && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="pocFinish"
                      placeholder="POC Finish"
                      onChange={(e) => handleChange(e)}
                      value={pocFinish}
                      readOnly={finishRequired.filter((t) => t.name === 'Others' && t.checked).length !== 1}
                      maxlength="10"
                      className={`pocFinish ${
                        finishRequired.filter((t) => t.name === 'Others' && t.checked).length === 1 ? 'require' : ''
                      }`}
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <div className="json-container json-require" id="packing">
                    <label>
                      Packing requirement <span className="red">*</span>
                    </label>
                    <Row>
                      {packing &&
                        packing.map((data, key) => {
                          return (
                            <Col key={key} breakPoint={{ xs: 12, md: 3 }}>
                              {data.name == 'Others' || data.name == 'Bale Size/Qty' ? (
                                <>
                                  <label className="othersLable">{data.name}</label>
                                  <Input className="othersParent" fullWidth size="Small">
                                    <input
                                      type="text"
                                      placeholder={data.name}
                                      onChange={(e) => onChangePackingCheckbox(e.target.value, key, data.name)}
                                      className="othersInput"
                                      value={data.text}
                                    />
                                  </Input>
                                </>
                              ) : (
                                <Checkbox
                                  checked={data.checked}
                                  status="Success"
                                  onChange={(value) => onChangePackingCheckbox(value, key, data.name)}
                                >
                                  {data.name}
                                </Checkbox>
                              )}
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>POC Status</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleStatusChange(e)}
                    options={statusOptions}
                    id="status"
                    name="status"
                    placeholder="status"
                    value={defaultStatus}
                    className="pull-down"
                  />
                </Col>

                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button
                    status="Success"
                    type="button"
                    className="btnrad"
                    shape=""
                    halfWidth
                    onClick={postApi}
                    disabled={!isSave}
                  >
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header id="list-data">Process Orders Confirmation</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => setTableFilter(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <Button
                onClick={() => {
                  if (page == 1) {
                    getApi();
                  } else {
                    setPage(1);
                  }
                }}
              >
                Filter
              </Button>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
              />
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePagination}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default POPage;
